<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="text-right mb-2"
      >
        <v-btn
          small
          elevation="10"
          class="text-xs font-weight-semibold"
          color="primary darken-2"
          :to="{ name: 'marketplace.create_fault' }"
        >
          <font-awesome-icon
            icon="fa-solid fa-plus"
            class="pe-1"
          />
          Create Faults
        </v-btn>
      </v-col>
    </v-row>

    <v-card>
      <v-row class="pt-2 mx-2 pt-0">
        <v-col
          cols="12"
          class="px-4 pt-2 mt-1 pb-0 mb-0"
        >
          <v-select
            v-model="selectedTopUp"
            outlined
            dense
            item-text="username"
            item-value="id"
            :items="topups"
            label="Filter By TopUps"
          ></v-select>
        </v-col>

        <v-col
          cols="5"
          xs="5"
          class="text-left mt-0 pt-0"
        >
          <v-text-field
            v-model="selectedStartDate"
            dense
            type="date"
            label="Start Date"
          ></v-text-field>
        </v-col>
        <v-col
          cols="5"
          xs="5"
          class="text-right mt-0 pt-0"
        >
          <v-text-field
            v-model="selectedEndDate"
            dense
            type="date"
            label="End Date"
          ></v-text-field>
        </v-col>
        <v-col
          cols="2"
          xs="2"
          class="text-right mt-0 pt-0"
        >
          <spinner v-if="loading" class="text-right"></spinner>
          <v-btn
            v-else
            icon small
            class="primary"
            @click="getFaults()"
          >
            <font-awesome-icon icon="fa-solid fa-search"/>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-title class="">
        <span class="me-3">({{ faults.length }}) Work Errors</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <h3 class="ml-3 text--center">
        <span v-if="faults_total">Total Faults: {{ faults_total }} F CFA</span>
      </h3> 
      <v-data-table
        :headers="headers"
        :items="faults"
        item-key="id"
        class="table-rounded"
        :items-per-page="10"
        disable-sort
        :search="search"
      >
        <!-- name -->
        <template #[`item.error`]="{item}">
          <div class="d-flex flex-column" style="cursor: pointer">
            <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.error }}</span>
            <small><b>Created by:</b> {{ item.topup_name }}</small>
          </div>
        </template>
        <template #[`item.amount`]="{item}">
          {{ item.amount }} F CFA
        </template>
        <!-- status -->
        <template #[`item.withdrawal`]="{item}">
          <v-chip
            small v-if="item.withdrawal"
            color="primary"
            class="font-weight-medium"
          >
            Withdrawal
          </v-chip>
          <v-chip
            small v-else
            color="success"
            class="font-weight-medium"
          >
            Deposit
          </v-chip>
        </template>
        <template #[`item.get_date_str`]="{item}">
          {{ item.get_date_str }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'

export default {
  data() {

    return {
      headers: [
        { text: 'Error', value: 'error' },
        { text: 'Description', value: 'message' },
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'withdrawal' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      selectedTopUp: null,
      topups: [],
      loading: false,

      faults: [],
      faults_total: "",
      search: '',
      selectedStartDate: null,
      selectedEndDate: null,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
  
  mounted(){
      // executes these after the page has been mounted
      this.getTopUps();
      document.title = "Manager: Market Place | List of Faults"
  },

  methods:{

    getTopUps(){
      axios
        .get('/api/v1/manager/get/topups/')
        .then(response => {
          this.topups = response.data  // get the data and fill into campaigns
          console.log(this.users)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    getFaults(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      if (!this.selectedTopUp|| this.selectedTopUp === ''){
        this.selectedTopUp = 'All'
      }
      axios
        .get('/api/v1/manager/market_place/get/faults/'+this.selectedTopUp+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.faults = response.data["faults"]
          this.faults_total = response.data["faults_total"]
          console.log(this.faults)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },

    getMoreInfo(info_id){
      this.$store.commit('setDialogTitle', "Getting More Information for "+info_id)
      this.$store.commit('setDialogMessage', "Your information has been collected. <b>After</b> choosing this, the next field you will have to choose the number of days/weeks/months. <ul><li>This is one</li><li>This is two</li><li>This is three</li><li>This is four</li></ul>")
      this.$store.commit('activateDialog', true)
    }
  },
}
</script>
